<template>
  <div v-if="searchCardStore?.ProcessingForm_validFrom">
    <h2 v-html="$t('search_finalisation_title')"></h2>
    <!-- Abschlussmonat von -->
    <DbmMonthPicker v-model="searchCardStore.ProcessingForm_validFrom" :date-label="$t('search_finalization_valid_from')" :clearable="true" />
    <!-- Abschlussmonat bis -->
    <DbmMonthPicker
      v-model="searchCardStore.ProcessingForm_validUntil"
      :dateLabel="$t('search_finalization_valid_until')"
      :clearable="true"
      returnType="lastOfMonth"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import { useSearchProcessingFormStore } from '@/store/SearchProcessingFormStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchFinalisation',
  extends: baseSearchVue,
  data() {
    return {
      menuFrom: false,
      menuTo: false
    }
  },
  components: {
    DbmMonthPicker: DbmMonthPicker
  },
  beforeMount() {
    this.initSearchStoreFromQuery(useSearchProcessingFormStore(), this.$route.query)
  }
})
</script>
